import DocumentContext from "../../../providers/DocumentContext"
import Array from "./TypesVariables/Array"
import ArrayMultiObjects from "./TypesVariables/ArrayMultiObjects"
import Bool from "./TypesVariables/Bool"
import Composables from "./TypesVariables/Composables"
import Choice from "./TypesVariables/Choice"
import List from "./TypesVariables/List"
import MultiSignatures from "./TypesVariables/MultiSignatures"
import MultipleChoice from "./TypesVariables/MultipleChoice"
import Object from "./TypesVariables/Object"
import Placeholder from "./TypesVariables/Placeholder"
import Signature from "./TypesVariables/Signature"
import TemplateLib from "@common/libs/interview-template-lib"
import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Grid,
  InputLabel,
  ListSubheader,
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import TextField from "@material-ui/core/TextField"
import { Alert } from "@material-ui/lab/"
import React, { useContext } from "react"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import BadgeLabel from "../BadgeLabel"
import checkOrder from "./checkOrder"
import WysiwygEditor from "./WysiwygEditor";

export default function FormDialog({ variableDialog, setvariableDialog }) {
  const { document, updateDocument } = useContext(DocumentContext)
  console.log("document", document)
  const location = useLocation()
  const typesVariables = [
    { type: "text", label: "Texte" },
    { type: "number", label: "Nombre" },
    { type: "date", label: "Date" },
    { type: "object", label: "Objet" },
    { type: "unisign", label: "Signature" },
    { type: "multisign", label: "Signatures mutliples" },
    { type: "textformat", label: "Wysiwyg" },
    { type: "bool", label: "Oui / Non" },
    { type: "composable", label: "Composables" },
    { type: "choice", label: "Choix" },
    { type: "multi", label: "Choix multiples" },
    { type: "list", label: "Liste" },
    { type: "array", label: "Tableau" },
    { type: "multiarray", label: "Tableau multi-objets" },
  ]

  const [originalvariableGroup, setoriginalvariableGroup] = React.useState(
    variableDialog?.group
  )

  if (variableDialog?.database_object_model_fields_mapping === null) {
    variableDialog.database_object_model_fields_mapping = [
      {
        database_object_model_name: "",
        mappingType: "simple",
        data: [
          {
            mapping_complex: "",
            database_object_model_field_name: "",
            variable_field_name: "",
          },
        ],
      },
    ]
  }

  const sendVariable = () => {
    let result = { ...document }
    const id = location.pathname.slice(11, location.pathname.length)
    let copy = { ...variableDialog }
    if (copy.dependant_variable === 0) {
      copy.dependant_variable = null
    }
    if (copy.dependant_variable_2 === 0) {
      copy.dependant_variable_2 = null
    }
    if (copy.dependant_variable_3 === 0) {
      copy.dependant_variable_3 = null
    }
    if (copy.type === "bool") {
      copy.options = null
    }
    const getValue = (elem) => {
      const type = variableDialog.options.find((e) => e.id === elem.id).type
      if (type === "text") return String(elem.value)
      else if (type === "number")
        return elem.value === "" ? 0 : Number(elem.value)
      else if (type === "bool") return Boolean(elem.value)
      else return String(elem.value)
    }
    if (copy.type === "object") {
      copy.placeholder = copy.placeholder.map((el) => ({
        ...el,
        value: getValue(el),
      }))
    } else if (copy.type === "array") {
      copy.placeholder[0] = copy.placeholder[0].map((el) => ({
        ...el,
        value: getValue(el),
      }))
    }

    if (copy.id) {
      TemplateLib.updateVariable(copy.id, copy)
        .then((response) => {
          TemplateLib.assignVariable(
            response.id,
            copy.is_global ? [] : [...copy.assigned_documents, id]
          )
            .then((res) => {
              result.variables = document.variables.map((el) => {
                if (el.id === copy.id) {
                  return {
                    ...copy,
                    assigned_documents: copy.is_global
                      ? []
                      : [...copy.assigned_documents, id],
                  }
                } else return el
              })
              if (originalvariableGroup !== copy.group) {
                const vars = result.variables.filter(
                  (el) => el.group === originalvariableGroup
                )
                checkOrder({ toOrder: vars, isGroup: false }).then(
                  (orderedVariables) => {
                    orderedVariables.map((orderedVar) => {
                      const myVar = result.variables.find((resultVariable) => {
                        return orderedVar.id === resultVariable.id
                      })
                      result.variables[result.variables.indexOf(myVar)] =
                        orderedVar
                    })
                    updateDocument(result)
                  }
                )
              } else {
                updateDocument(result)
              }

              toast.success("Modification de la variable enregistrée.")
              setvariableDialog(null)
            })
            .catch((e) => {
              console.error("error", e)
              toast.error("Erreur durant la modification de la variable")
            })
        })
        .catch((err) => {
          if (err.response.status === 500) {
            const first_part = err.response.data.split(
              'class="exception_value">'
            )[1]
            const second_part = first_part.split("</pre>")[0]
            const result = second_part.slice(":")[0]
            toast.error("Erreur durant l'ajout de la variable : " + result)
          } else if (err.response.status === 400) {
            toast.error(
              "Erreur durant l'ajout de la variable : " +
                err.response.data.non_field_errors
            )
          }
        })
    } else {
      delete copy.id
      TemplateLib.createVariable({
        ...copy,
        project: document.project,
        order_in_group:
          document.variables.filter((el) => el.group === copy.group).length + 1,
      })
        .then((response) => {
          TemplateLib.assignVariable(
            response.id,
            copy.is_global ? [] : [id]
          ).then((res) => {
            result.variables = [
              ...document.variables,
              { ...response, assigned_documents: copy.is_global ? [] : [id] },
            ]
            updateDocument(result)
            toast.success("Ajout de la variable enregistrée.")
            setvariableDialog(null)
          })
        })
        .catch((err) => {
          if (err.response.status === 500) {
            const first_part = err.response.data.split(
              'class="exception_value">'
            )[1]
            const second_part = first_part.split("</pre>")[0]
            const result = second_part.slice(":")[0]
            toast.error("Erreur durant l'ajout de la variable : " + result)
          } else if (err.response.status === 400) {
            toast.error(
              "Erreur durant l'ajout de la variable : " +
                err.response.data.non_field_errors
            )
          }
        })
    }
  }

  const handleClose = () => {
    if (variableDialog.dependant_variable === 0) {
      variableDialog.dependant_variable = null
    }
    if (variableDialog.dependant_variable_2 === 0) {
      variableDialog.dependant_variable_2 = null
    }
    if (variableDialog.dependant_variable_3 === 0) {
      variableDialog.dependant_variable_3 = null
    }
    if (variableDialog.type === "bool") {
      variableDialog.options = null
    }
    setvariableDialog(false)
  }

  const [originalvariableType, setoriginalvariableType] = React.useState(
    variableDialog?.type
  )

  React.useEffect(() => {
    if (variableDialog?.id) {
      setoriginalvariableType(variableDialog.type)
      setoriginalvariableGroup(variableDialog.group)
    } else {
      setoriginalvariableType("text")
    }
  }, [variableDialog?.id])

  const getValidation = () => {
    let valid = false

    // if (variableDialog?.type === "choice" || variableDialog?.type === "multi") {
    //     if (variableDialog.options) {
    //         variableDialog.options.forEach(e => {
    //             if (e.trim() === "") valid = true;
    //         });
    //     }
    // } else if (variableDialog?.type === "object" || variableDialog?.type === "array") {
    //     if (variableDialog.options) {
    //         variableDialog.options.forEach(e => {
    //             if (e.label) {
    //                 if (e.label.trim() === "") valid = true;
    //             }
    //         });
    //     }
    // }
    return valid
  }

  const isBool = (questionId) => {
    const dependant_question = document.variables.filter(
      (elem) => elem.id == questionId
    )[0]
    if (dependant_question?.type === "bool") {
      return true
    } else {
      return false
    }
  }

  const isSingleChoice = (questionId) => {
    var dependant_question = document.variables.filter(
      (elem) => elem.id == questionId
    )[0]
    if (dependant_question?.type === "choice") {
      return true
    } else {
      return false
    }
  }

  const isMultiChoice = (questionId) => {
    var dependant_question = document.variables.filter(
      (elem) => elem.id == questionId
    )[0]
    if (dependant_question?.type === "multi") {
      return true
    } else {
      return false
    }
  }

  const boolDependantValueSelectItems = [
    {
      key: 1,
      value: "true",
      text: "Oui",
    },
    {
      key: 2,
      value: "false",
      text: "Non",
    },
  ]

  if (
    variableDialog &&
    (variableDialog.dependant_variable == null ||
      variableDialog.dependant_variable == undefined)
  ) {
    variableDialog.dependant_variable = 0
    variableDialog.dependant_value = 0
  }

  if (
    variableDialog &&
    (variableDialog.dependant_variable_2 == null ||
      variableDialog.dependant_variable_2 == undefined)
  ) {
    variableDialog.dependant_variable_2 = 0
    variableDialog.dependant_value_2 = 0
  }

  if (
    variableDialog &&
    (variableDialog.dependant_variable_3 == null ||
      variableDialog.dependant_variable_3 == undefined)
  ) {
    variableDialog.dependant_variable_3 = 0
    variableDialog.dependant_value_3 = 0
  }

  if (
    variableDialog &&
    variableDialog.dependant_variable != null &&
    variableDialog.dependant_variable != 0
  ) {
    var dependant_question = document.variables.filter(
      (elem) => elem.id == variableDialog.dependant_variable
    )[0]
    if (dependant_question) {
      if (dependant_question.type === "bool") {
        if (
          variableDialog.dependant_value != "true" &&
          variableDialog.dependant_value != "false"
        ) {
          variableDialog.dependant_value = "true"
        }
      }
      if (
        dependant_question.type === "choice" ||
        dependant_question.type === "multi"
      ) {
        var dependantValueInChoices = false
        for (var i = 0; i < dependant_question.options.length; ++i) {
          if (variableDialog.dependant_value == dependant_question.options[i]) {
            dependantValueInChoices = true
          }
        }
        if (!dependantValueInChoices) {
          variableDialog.dependant_value = dependant_question.options[0]
        }
      }
    } else {
      setvariableDialog({
        ...variableDialog,
        dependant_variable: 0,
        dependant_value: 0,
      })
    }
  }

  if (
    variableDialog &&
    variableDialog.dependant_variable_2 != null &&
    variableDialog.dependant_variable_2 != 0
  ) {
    var dependant_question = document.variables.filter(
      (elem) => elem.id == variableDialog.dependant_variable_2
    )[0]
    if (dependant_question) {
      if (dependant_question.type === "bool") {
        if (
          variableDialog.dependant_value_2 != "true" &&
          variableDialog.dependant_value_2 != "false"
        ) {
          variableDialog.dependant_value_2 = "true"
        }
      }
      if (
        dependant_question.type === "choice" ||
        dependant_question.type === "multi"
      ) {
        var dependantValueInChoices = false
        for (var i = 0; i < dependant_question.options.length; ++i) {
          if (
            variableDialog.dependant_value_2 == dependant_question.options[i]
          ) {
            dependantValueInChoices = true
          }
        }
        if (!dependantValueInChoices) {
          variableDialog.dependant_value_2 = dependant_question.options[0]
        }
      }
    } else {
      setvariableDialog({
        ...variableDialog,
        dependant_variable_2: 0,
        dependant_value_2: 0,
      })
    }
  }

  if (
    variableDialog &&
    variableDialog.dependant_variable_3 != null &&
    variableDialog.dependant_variable_3 != 0
  ) {
    var dependant_question = document.variables.filter(
      (elem) => elem.id == variableDialog.dependant_variable_3
    )[0]
    if (dependant_question) {
      if (dependant_question.type === "bool") {
        if (
          variableDialog.dependant_value_3 != "true" &&
          variableDialog.dependant_value_3 != "false"
        ) {
          variableDialog.dependant_value_3 = "true"
        }
      }
      if (
        dependant_question.type === "choice" ||
        dependant_question.type === "multi"
      ) {
        var dependantValueInChoices = false
        for (var i = 0; i < dependant_question.options.length; ++i) {
          if (
            variableDialog.dependant_value_3 == dependant_question.options[i]
          ) {
            dependantValueInChoices = true
          }
        }
        if (!dependantValueInChoices) {
          variableDialog.dependant_value_3 = dependant_question.options[0]
        }
      }
    } else {
      setvariableDialog({
        ...variableDialog,
        dependant_variable_3: 0,
        dependant_value_3: 0,
      })
    }
  }

  return (
    variableDialog && (
      <Dialog open={variableDialog ? true : false} onClose={handleClose}>
        <div style={{ maxWidth: 1200 }}>
          <DialogTitle id="form-dialog-title">
            {variableDialog.id ? "Editer" : "Ajouter"} la variable
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Alert severity="info">
                  Veuillez renseigner les détails de la variable qui sera
                  remplie par l'utilisateur final.
                </Alert>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControl size="small" fullWidth variant="outlined">
                  <InputLabel>
                    Page de questionnaire associée (facultatif)
                  </InputLabel>
                  <Select
                    label="Page de questionnaire associée (facultatif)"
                    value={variableDialog.group}
                    onChange={(e) => {
                      const order =
                        document.variables.filter(
                          (el) => el.group === e.target.value
                        ).length + 1
                      setvariableDialog({
                        ...variableDialog,
                        group: e.target.value,
                        order_in_group: order,
                      })
                    }}
                  >
                    {document.groupsVariables.map((el, key) => (
                      <MenuItem key={key} value={el.id}>
                        {el.order > 9 ? el.order : "0" + el.order}. {el.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={(e) =>
                  setvariableDialog({
                    ...variableDialog,
                    is_global: !variableDialog.is_global,
                  })
                }
              >
                <span>Variable globale au projet : </span>
                <Checkbox checked={variableDialog.is_global} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  label="Nom de la variable *"
                  size="small"
                  error={variableDialog.name === ""}
                  fullWidth
                  variant="outlined"
                  value={variableDialog.name}
                  onChange={(e) =>
                    setvariableDialog({
                      ...variableDialog,
                      name: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  label="Question pour l'utilisateur *"
                  error={variableDialog.question === ""}
                  size="small"
                  multiline
                  rows={5}
                  fullWidth
                  variant="outlined"
                  value={variableDialog.question}
                  onChange={(e) =>
                    setvariableDialog({
                      ...variableDialog,
                      question: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
              <div style={{marginBottom: 5}}>Description de la variable</div>
              <WysiwygEditor _formData={variableDialog} set_formData={setvariableDialog} placeholder={"Description de la variable"}/>

              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControl size="small" fullWidth variant="outlined">
                  <InputLabel>Question dépendante (facultatif)</InputLabel>
                  <Select
                    label="Question dépendante (facultatif)"
                    value={variableDialog.dependant_variable}
                    onChange={(e) => {
                      setvariableDialog({
                        ...variableDialog,
                        dependant_variable: e.target.value,
                      })
                    }}
                  >
                    <MenuItem key={0} value={0}>
                      Aucune
                    </MenuItem>
                    {document.groupsVariables.map((el, key) => [
                      document.variables
                        .filter((elem) => elem.group === el.id)
                        .filter(
                          (elem) =>
                            elem.type === "bool" ||
                            elem.type === "choice" ||
                            elem.type === "multi"
                        ).length != 0 && (
                        <div>
                          <ListSubheader>
                            {el.order > 10 ? el.order : "0" + el.order}.{" "}
                            {el.name}
                          </ListSubheader>
                        </div>
                      ),
                      document.variables
                        .filter((elem) => elem.group === el.id)
                        .filter(
                          (elem) =>
                            elem.type === "bool" ||
                            elem.type === "choice" ||
                            elem.type === "multi"
                        )
                        .sort((a, b) => {
                          return a.id - b.id
                        })
                        .map((el, key) => (
                          <MenuItem key={el.id} value={el.id}>
                            #{el.id} - {el.name}
                          </MenuItem>
                        )),
                    ])}
                    <ListSubheader>Informations complémentaires</ListSubheader>
                    {document.variables
                      .filter((elem) => elem.group === null)
                      .filter(
                        (elem) =>
                          elem.type === "bool" ||
                          elem.type === "choice" ||
                          elem.type === "multi"
                      )
                      .sort((a, b) => {
                        return a.id - b.id
                      })
                      .map((el, key) => (
                        <MenuItem key={el.id} value={el.id}>
                          #{el.id} - {el.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <FormControl size="small" fullWidth variant="outlined">
                  <InputLabel>Valeur de la question dépendante</InputLabel>
                  <Select
                    label="Valeur de la question dépendante"
                    value={variableDialog.dependant_value}
                    onChange={(e) => {
                      setvariableDialog({
                        ...variableDialog,
                        dependant_value: e.target.value,
                      })
                    }}
                  >
                    {variableDialog && variableDialog.dependant_variable == 0 && (
                      <MenuItem key={0} value={0}>
                        -
                      </MenuItem>
                    )}
                    {variableDialog &&
                      variableDialog.dependant_variable != 0 &&
                      isBool(variableDialog.dependant_variable) &&
                      boolDependantValueSelectItems.map((el, key) => (
                        <MenuItem key={el.key} value={el.value}>
                          {el.text}
                        </MenuItem>
                      ))}
                    {variableDialog &&
                      variableDialog.dependant_variable != 0 &&
                      isSingleChoice(variableDialog.dependant_variable) &&
                      document.variables
                        .filter(
                          (elem) => elem.id == variableDialog.dependant_variable
                        )[0]
                        .options.map((el, key) => (
                          <MenuItem key={key + 1} value={el}>
                            {el}
                          </MenuItem>
                        ))}
                    {variableDialog &&
                      variableDialog.dependant_variable != 0 &&
                      isMultiChoice(variableDialog.dependant_variable) &&
                      document.variables
                        .filter(
                          (elem) => elem.id == variableDialog.dependant_variable
                        )[0]
                        .options.map((el, key) => (
                          <MenuItem key={key + 1} value={el}>
                            {el}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                {/* 2*/}
                <FormControl size="small" fullWidth variant="outlined">
                  <InputLabel>Question dépendante (facultatif)</InputLabel>
                  <Select
                    label="Question dépendante (facultatif)"
                    value={variableDialog.dependant_variable_2}
                    onChange={(e) => {
                      setvariableDialog({
                        ...variableDialog,
                        dependant_variable_2: e.target.value,
                      })
                    }}
                  >
                    <MenuItem key={0} value={0}>
                      Aucune
                    </MenuItem>
                    {document.groupsVariables.map((el, key) => [
                      document.variables
                        .filter((elem) => elem.group === el.id)
                        .filter(
                          (elem) =>
                            elem.type === "bool" ||
                            elem.type === "choice" ||
                            elem.type === "multi"
                        ).length != 0 && (
                        <div>
                          <ListSubheader>
                            {el.order > 10 ? el.order : "0" + el.order}.{" "}
                            {el.name}
                          </ListSubheader>
                        </div>
                      ),
                      document.variables
                        .filter((elem) => elem.group === el.id)
                        .filter(
                          (elem) =>
                            elem.type === "bool" ||
                            elem.type === "choice" ||
                            elem.type === "multi"
                        )
                        .sort((a, b) => {
                          return a.id - b.id
                        })
                        .map((el, key) => (
                          <MenuItem key={el.id} value={el.id}>
                            #{el.id} - {el.name}
                          </MenuItem>
                        )),
                    ])}
                    <ListSubheader>Informations complémentaires</ListSubheader>
                    {document.variables
                      .filter((elem) => elem.group === null)
                      .filter(
                        (elem) =>
                          elem.type === "bool" ||
                          elem.type === "choice" ||
                          elem.type === "multi"
                      )
                      .sort((a, b) => {
                        return a.id - b.id
                      })
                      .map((el, key) => (
                        <MenuItem key={el.id} value={el.id}>
                          #{el.id} - {el.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <FormControl size="small" fullWidth variant="outlined">
                  <InputLabel>Valeur de la question dépendante</InputLabel>
                  <Select
                    label="Valeur de la question dépendante"
                    value={variableDialog.dependant_value_2}
                    onChange={(e) => {
                      setvariableDialog({
                        ...variableDialog,
                        dependant_value_2: e.target.value,
                      })
                    }}
                  >
                    {variableDialog &&
                      variableDialog.dependant_variable_2 == 0 && (
                        <MenuItem key={0} value={0}>
                          -
                        </MenuItem>
                      )}
                    {variableDialog &&
                      variableDialog.dependant_variable_2 != 0 &&
                      isBool(variableDialog.dependant_variable_2) &&
                      boolDependantValueSelectItems.map((el, key) => (
                        <MenuItem key={el.key} value={el.value}>
                          {el.text}
                        </MenuItem>
                      ))}
                    {variableDialog &&
                      variableDialog.dependant_variable_2 != 0 &&
                      isSingleChoice(variableDialog.dependant_variable_2) &&
                      document.variables
                        .filter(
                          (elem) =>
                            elem.id == variableDialog.dependant_variable_2
                        )[0]
                        .options.map((el, key) => (
                          <MenuItem key={key + 1} value={el}>
                            {el}
                          </MenuItem>
                        ))}
                    {variableDialog &&
                      variableDialog.dependant_variable_2 != 0 &&
                      isMultiChoice(variableDialog.dependant_variable_2) &&
                      document.variables
                        .filter(
                          (elem) =>
                            elem.id == variableDialog.dependant_variable_2
                        )[0]
                        .options.map((el, key) => (
                          <MenuItem key={key + 1} value={el}>
                            {el}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                {/* 3  */}
                <FormControl size="small" fullWidth variant="outlined">
                  <InputLabel>Question dépendante (facultatif)</InputLabel>
                  <Select
                    label="Question dépendante (facultatif)"
                    value={variableDialog.dependant_variable_3}
                    onChange={(e) => {
                      setvariableDialog({
                        ...variableDialog,
                        dependant_variable_3: e.target.value,
                      })
                    }}
                  >
                    <MenuItem key={0} value={0}>
                      Aucune
                    </MenuItem>
                    {document.groupsVariables.map((el, key) => [
                      document.variables
                        .filter((elem) => elem.group === el.id)
                        .filter(
                          (elem) =>
                            elem.type === "bool" ||
                            elem.type === "choice" ||
                            elem.type === "multi"
                        ).length != 0 && (
                        <div>
                          <ListSubheader>
                            {el.order > 10 ? el.order : "0" + el.order}.{" "}
                            {el.name}
                          </ListSubheader>
                        </div>
                      ),
                      document.variables
                        .filter((elem) => elem.group === el.id)
                        .filter(
                          (elem) =>
                            elem.type === "bool" ||
                            elem.type === "choice" ||
                            elem.type === "multi"
                        )
                        .sort((a, b) => {
                          return a.id - b.id
                        })
                        .map((el, key) => (
                          <MenuItem key={el.id} value={el.id}>
                            #{el.id} - {el.name}
                          </MenuItem>
                        )),
                    ])}
                    <ListSubheader>Informations complémentaires</ListSubheader>
                    {document.variables
                      .filter((elem) => elem.group === null)
                      .filter(
                        (elem) =>
                          elem.type === "bool" ||
                          elem.type === "choice" ||
                          elem.type === "multi"
                      )
                      .sort((a, b) => {
                        return a.id - b.id
                      })
                      .map((el, key) => (
                        <MenuItem key={el.id} value={el.id}>
                          #{el.id} - {el.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <FormControl size="small" fullWidth variant="outlined">
                  <InputLabel>Valeur de la question dépendante</InputLabel>
                  <Select
                    label="Valeur de la question dépendante"
                    value={variableDialog.dependant_value_3}
                    onChange={(e) => {
                      setvariableDialog({
                        ...variableDialog,
                        dependant_value_3: e.target.value,
                      })
                    }}
                  >
                    {variableDialog &&
                      variableDialog.dependant_variable_3 == 0 && (
                        <MenuItem key={0} value={0}>
                          -
                        </MenuItem>
                      )}
                    {variableDialog &&
                      variableDialog.dependant_variable_3 != 0 &&
                      isBool(variableDialog.dependant_variable_3) &&
                      boolDependantValueSelectItems.map((el, key) => (
                        <MenuItem key={el.key} value={el.value}>
                          {el.text}
                        </MenuItem>
                      ))}
                    {variableDialog &&
                      variableDialog.dependant_variable_3 != 0 &&
                      isSingleChoice(variableDialog.dependant_variable_3) &&
                      document.variables
                        .filter(
                          (elem) =>
                            elem.id == variableDialog.dependant_variable_3
                        )[0]
                        .options.map((el, key) => (
                          <MenuItem key={key + 1} value={el}>
                            {el}
                          </MenuItem>
                        ))}
                    {variableDialog &&
                      variableDialog.dependant_variable_3 != 0 &&
                      isMultiChoice(variableDialog.dependant_variable_3) &&
                      document.variables
                        .filter(
                          (elem) =>
                            elem.id == variableDialog.dependant_variable_3
                        )[0]
                        .options.map((el, key) => (
                          <MenuItem key={key + 1} value={el}>
                            {el}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl size="small" fullWidth variant="outlined">
                  {/* <InputLabel>Lien composite</InputLabel> */}
                  <TextField
                    label="Lien composite"
                    size="small"
                    fullWidth
                    variant="outlined"
                    value={variableDialog.composite_link}
                    onChange={(e) =>
                      setvariableDialog({
                        ...variableDialog,
                        composite_link: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl size="small" fullWidth variant="outlined">
                  <InputLabel>Type de la variable</InputLabel>
                  <Select
                    disabled={variableDialog.id}
                    label="Type de la variable"
                    value={variableDialog.type}
                    onChange={(e) => {
                      setvariableDialog({
                        ...variableDialog,
                        type: e.target.value,
                      })
                    }}
                    renderValue={(value) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <BadgeLabel type={value} />
                        <span style={{ marginLeft: 10 }}>
                          {" "}
                          {typesVariables.find((e) => e.type === value).label}
                        </span>
                      </div>
                    )}
                  >
                    {typesVariables.map((el, key) => (
                      <MenuItem key={key} value={el.type}>
                        <BadgeLabel type={el.type} />
                        <span style={{ marginLeft: 10 }}> {el.label}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {getTypeVariable({
                variableDialog,
                setvariableDialog,
                originalvariableType,
                setoriginalvariableType,
              })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained">
              Annuler
            </Button>
            <Button
              onClick={sendVariable}
              variant="contained"
              color="primary"
              disabled={
                variableDialog.type === "" ||
                variableDialog.name === "" ||
                variableDialog.question === "" ||
                getValidation()
              }
            >
              {variableDialog.id ? "Enregistrer" : "Ajouter"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    )
  )
}

export function getTypeVariable(props) {
  const type = props.variableDialog.type

  if (
    type === "text" ||
    type === "number" ||
    type === "date" ||
    type === "textformat"
  )
    return <Placeholder type={type} {...props} />
  else if (type === "bool") return <Bool {...props} />
  else if (type === "composable") return <Composables {...props} />
  else if (type === "choice") return <Choice {...props} />
  else if (type === "multi") return <MultipleChoice {...props} />
  else if (type === "list") return <List {...props} />
  else if (type === "object") return <Object {...props} />
  else if (type === "array") return <Array {...props} />
  else if (type === "unisign") return <Signature {...props} />
  else if (type === "multisign") return <MultiSignatures {...props} />
  else if (type === "multiarray") return <ArrayMultiObjects {...props} />
  else return <div>Erreur : Aucun type de variable associé</div>
}
