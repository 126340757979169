import DocumentContext from "../../../../providers/DocumentContext";
import { Grid, Button } from "@material-ui/core/";
import React, { useState, useContext } from "react";

export default ({
  onSave,
  onCopy,
  showCopy,
  disabled,
  copyButtonDisabled,
  setOpen,
  isFromWordIntegration,
}) => {
  const { document, updateDocument } = useContext(DocumentContext);
  const [used, setused] = useState(false);
  return (
    <div>
      {showCopy ? (
        isFromWordIntegration ? (
          <Grid container spacing={4} style={{ padding: 10 }}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  updateDocument({
                    ...document,
                    selection: "",
                    edit: { type: null },
                  });
                  setOpen(false);
                }}
              >
                Annuler
              </Button>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Button
                disabled={disabled || used || copyButtonDisabled}
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  onCopy();
                }}
              >
                Copier
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={4} style={{ padding: 10 }}>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  updateDocument({
                    ...document,
                    selection: "",
                    edit: { type: null },
                  });
                  setOpen(false);
                }}
              >
                Annuler
              </Button>
            </Grid>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Button
                disabled={disabled || used}
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  setused(true);
                  onSave();
                }}
              >
                Sauvegarder
              </Button>
            </Grid>
            <Grid item lg={2} md={2} sm={12} xs={12}>
              <Button
                disabled={disabled || used || copyButtonDisabled}
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  onCopy();
                  setOpen(false);
                }}
              >
                Copier
              </Button>
            </Grid>
          </Grid>
        )
      ) : (
        <Grid container spacing={4} style={{ padding: 10 }}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                updateDocument({
                  ...document,
                  selection: "",
                  edit: { type: null },
                });
                setOpen(false);
              }}
            >
              Annuler
            </Button>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Button
              disabled={disabled || used}
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                setused(true);
                onSave();
              }}
            >
              Sauvegarder
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
