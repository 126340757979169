////// React Core :
import ActiveEditorContext from "../../../../providers/ActiveEditorContext";
import StoreDataContext from "../../../../providers/StoreDataContext";
import TreeContext from "../../../../providers/TreeContext";
import WysiwygTaskEditor from "../WysiwygTaskEditor";
import Delete from "./Buttons/Delete";
import Modify from "./Buttons/Modify";
import MandatoryField from "./Fields/Mandatory";
import Dependencies from "./Fields/Dependencies";
import NameField from "./Fields/Name";
import getIconTask from "./Functions/getIconTask";
import getTaskType from "./Functions/getTaskType";
import TemplateLibInterview from "@common/libs/interview-template-lib";
import StripeLib from "@common/libs/stripe-lib";
import TemplateLib from "@common/libs/template-lib";
import {
  Grid,
  Button,
  Divider,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import FiltersContext from "~/providers/FiltersContext";
import config from "~/config";
import TaskStatuses from "./Fields/TaskStatuses";

export default function FormTask() {
  const { activeEditor, updateActiveEditor } = useContext(ActiveEditorContext);
  const { tree, updateTree } = useContext(TreeContext);
  const { storeData } = useContext(StoreDataContext);
  const [_loading, set_loading] = useState(false);
  const [loading, setloading] = useState(false);
  const [categories, setCategories] = useState([]);
  const { filtersData, updateFiltersData } = useContext(FiltersContext);

  useEffect(() => {
    TemplateLib.getListCategoriesOfProject(activeEditor.data.project_id).then(
      (categories) => {
        let sortedCategories = [];
        let sortedParentsCategories = [];
        sortedParentsCategories = categories
          .filter((categorie) => categorie.category_id === null)
          .sort((a, b) => a.order - b.order);

        sortedParentsCategories.forEach((sortedCategorie) => {
          const subCategories = categories
            .filter((cat) => cat.category_id === sortedCategorie.id)
            .sort((a, b) => a.order - b.order);
          sortedCategories.push(sortedCategorie, ...subCategories);
        });

        setCategories(sortedCategories);
      }
    );
  }, []);

  const getRealData = (fromData) => {
    if (typeof fromData.actions[0]?.data === "string") {
      let result = { ...fromData };
      const json = JSON.parse(result.actions[0].data);
      result.actions[0].data = json;
      if (result.actions[0].type === "form") {
        result.actions[0].data.schema = result.actions[0].data.schema.map(
          (elem) => {
            if (elem.type === "radio") {
              let res = { ...elem };
              res.radio = res.radio.map((el, key) => el.label);
              return res;
            } else if (elem.type === "select") {
              let res = { ...elem };
              res.list = res.list.map((el, key) => el.label);
              return res;
            } else return elem;
          }
        );
      } else if (result.actions[0].type === "payment") {
        result.actions[0].data.tax_rate =
          result.actions[0].data.tax_rate.percentage;
      }
      return result;
    } else return fromData;
  };

  const getFormattedData = (formData) => {
    let formattedData = { ...formData };

    if (formattedData.actions[0].type === "form") {
      formattedData.actions[0].data.schema =
        formattedData.actions[0].data.schema.map((elem) => {
          if (elem.type === "radio") {
            let result = { ...elem };
            result.radio = result.radio.map((el, key) => ({
              value: key,
              label: el,
            }));
            return result;
          } else if (elem.type === "select") {
            let result = { ...elem };
            result.list = result.list.map((el, key) => ({
              value: key,
              label: el,
            }));
            return result;
          } else return elem;
        });
    } else if (formattedData.actions[0].type === "payment") {
      formattedData.actions[0].data.products = _paymentForm.products;
      formattedData.actions[0].data.tax_rate = storeData.taxes.find(
        (el) => el.percentage === _paymentForm.tax_rate
      );
    }
    formattedData.actions[0].data = JSON.stringify(
      formattedData.actions[0].data
    );

    if (formattedData.actions[0].description === "")
      formattedData.actions[0].description = "/";
    if (formattedData.actions[0].interview_id === null)
      delete formattedData.actions[0].interview_id;
    if (formattedData.actions[0].interview_response_id === null)
      delete formattedData.actions[0].interview_response_id;

    return formattedData;
  };

  const [_formData, set_formData] = useState(getRealData(activeEditor.data));
  const [_paymentForm, set_paymentForm] = useState(null);

  let result = { ...tree };
  let projectIndex = activeEditor.root_key[0];
  if (Array.isArray(result.projects)) {
    projectIndex = result.projects?.findIndex(
      (el) => el.id === activeEditor.root[0].id
    );
  }
  let sectionIndex = activeEditor.root_key[1];
  let taskIndex = activeEditor.root_key.at(-1);
  if (activeEditor.root_key.length === 4) {
    sectionIndex = activeEditor.root_key[1];
    taskIndex = activeEditor.root_key[3];
  } else {
    sectionIndex = activeEditor.root_key[1];
    taskIndex = activeEditor.root_key[2];
  }

  const editTask = () => {
    const dataTask = { ..._formData, actions: [] };
    set_loading(true);
    TemplateLib.editTask(activeEditor.data.id, dataTask)
      .then((res) => {
        if (_formData.actions[0].type === "payment") {
          StripeLib.editProduct(_formData.actions[0].data.product_uuid, {
            name: _paymentForm.products.map((el) => el.name).join(" + "),
          }).then((res) => {
            StripeLib.editPrice(_formData.actions[0].data.price_uuid, {
              name: _paymentForm.products.map((el) => el.name).join(" + "),
              amount: getPrice(_paymentForm.products),
              product: res.uuid,
              amountType: "one_time",
            }).then((resolve) => {
              addValidTask();
            });
          });
        } else if (_formData.actions[0].type === "interview") {
          TemplateLibInterview.getDocumentsFromTask(activeEditor.data.id).then(
            (res) => {
              const toChange = _formData.actions[0].data.interview_id?.length
                ? res.filter((el) =>
                    _formData.actions[0].data.interview_id.find(
                      (e) => +e !== +el.id
                    )
                  )
                : res;
              Promise.all(
                toChange.map((el) =>
                  TemplateLibInterview.updateDocument(el.id, {
                    ...el,
                    task: null,
                  })
                )
              )
                .then(() => {
                  Promise.all(
                    _formData.actions[0].data.interview_id.map((el) =>
                      TemplateLibInterview.getDocument(el)
                    )
                  )
                    .then((docs) => {
                      Promise.all(
                        docs.map((el) =>
                          TemplateLibInterview.updateDocument(el.id, {
                            ...el,
                            task: activeEditor.data.id,
                          })
                        )
                      )
                        .then(() => {
                          addValidTask();
                        })
                        .catch((response) => {
                          config.handleErrorBackend({ response, message: "" });
                          addValidTask();
                        });
                    })
                    .catch((response) => {
                      config.handleErrorBackend({ response, message: "" });
                      addValidTask();
                    });
                })
                .catch((response) => {
                  config.handleErrorBackend({ response, message: "" });
                  addValidTask();
                });
            }
          );
        } else {
          addValidTask();
        }
      })
      .catch((response) => {
        set_loading(false);
        config.handleErrorBackend({ response, message: "" });
      });
  };

  const addValidTask = () => {
    TemplateLib.setActionsTask(
      activeEditor.data.id,
      getFormattedData(_formData).actions
    )
      .then(async (response) => {
        let realResult = { ..._formData };
        realResult.actions = _formData.actions;

        updateActiveEditor({
          ...activeEditor,
          data: realResult,
        });
        updateFiltersData(() => ({
          ...filtersData,
        }));
        toast.success(
          'Modification(s) de la tâche : "' +
            _formData.name +
            '" enregistrée(s).'
        );
        set_loading(false);
      })
      .catch((response) => {
        config.handleErrorBackend({ response, message: "" });
      });
  };

  const deleteTask = () => {
    if (
      window.confirm("Supprimer la tâche : " + activeEditor.data.name + " ?")
    ) {
      set_loading(true);

      if (activeEditor.data?.actions[0]?.type === "interview") {
        TemplateLibInterview.getDocumentsFromTask(activeEditor.data.id).then(
          (res) => {
            Promise.all(
              res.map((el) =>
                TemplateLibInterview.updateDocument(el.id, {
                  ...el,
                  task: null,
                })
              )
            ).then(() => {
              TemplateLib.deleteTask(activeEditor.data.id)
                .then((res) => {
                  let tasksOrder = tree.projects[
                    activeEditor.root_key[0]
                  ].sections[activeEditor.root_key[1]].tasks.filter(
                    (el) =>
                      el.id !== activeEditor.data.id &&
                      el.order > activeEditor.data.order
                  );
                  Promise.all(
                    tasksOrder.map((elem) =>
                      TemplateLib.editTask(elem.id, {
                        ...elem,
                        actions: [],
                        risk: "/",
                        order: (elem.order -= 1),
                      })
                    )
                  ).then((res) => {
                    updateActiveEditor({ type: null, data: null });
                    updateFiltersData(() => ({
                      ...filtersData,
                    }));
                    toast.success(
                      'Suppression de la tâche : "' +
                        activeEditor.data.name +
                        '" enregistrée.'
                    );
                    set_loading(false);
                  });
                })
                .catch((response) => {
                  set_loading(false);
                  config.handleErrorBackend({ response, message: "" });
                });
            });
          }
        );
      } else {
        TemplateLib.deleteTask(activeEditor.data.id)
          .then((res) => {
            if (
              activeEditor.data.order !== undefined ||
              ctiveEditor.data.order !== null
            ) {
              let tasksOrder = tree.projects[activeEditor.root_key[0]].sections[
                activeEditor.root_key[1]
              ].tasks.filter(
                (el) =>
                  el.id !== activeEditor.data.id &&
                  el.order > activeEditor.data.order
              );
              Promise.all(
                tasksOrder.map((elem) =>
                  TemplateLib.editTask(elem.id, {
                    ...elem,
                    actions: [],
                    risk: "/",
                    order: (elem.order -= 1),
                  })
                )
              ).then((res) => {
                updateActiveEditor({ type: null, data: null });
                updateFiltersData(() => ({
                  ...filtersData,
                }));
                set_loading(false);

                toast.success(
                  'Suppression de la tâche : "' +
                    activeEditor.data.name +
                    '" enregistrée.'
                );
              });
            } else {
              updateFiltersData(() => ({
                ...filtersData,
              }));
              updateActiveEditor({ type: null, data: null });
              set_loading(false);

              toast.success(
                'Suppression de la tâche : "' +
                  activeEditor.data.name +
                  '" enregistrée.'
              );
            }
          })
          .catch((response) => {
            set_loading(false);
            config.handleErrorBackend({ response, message: "" });
          });
      }
    }
  };

  const OrderUp = () => {
    if (taskIndex - 1 !== -1) {
      if (activeEditor.root_key.length === 3) {
        projectIndex = activeEditor.root_key[0];
        sectionIndex = activeEditor.root_key[1];
        taskIndex = activeEditor.root_key[2];
        setloading(true);
        const prev =
          result.projects[projectIndex].sections[sectionIndex].tasks[
            taskIndex - 1
          ];
        result.projects[projectIndex].sections[sectionIndex].tasks[
          taskIndex - 1
        ] = { ...activeEditor.data, order: prev.order };
        result.projects[projectIndex].sections[sectionIndex].tasks[taskIndex] =
          {
            ...prev,
            order: activeEditor.data.order,
          };
        TemplateLib.editTask(activeEditor.data.id, {
          ...activeEditor.data,
          order: prev.order,
          actions: [],
        })
          .then(() => {
            TemplateLib.editTask(prev.id, {
              ...prev,
              order: activeEditor.data.order,
              actions: [],
            })
              .then(() => {
                updateTree({
                  ...result,
                  selected: [projectIndex, sectionIndex, taskIndex - 1].join(
                    "-"
                  ),
                });
                updateActiveEditor({
                  ...activeEditor,
                  root_key: [projectIndex, sectionIndex, taskIndex - 1],
                  data: { ...activeEditor.data, order: prev.order },
                });
                setloading(false);
              })
              .catch((response) =>
                config.handleErrorBackend({ response, message: "" })
              );
          })
          .catch((response) =>
            config.handleErrorBackend({ response, message: "" })
          );
      }
      if (activeEditor.root_key.length === 4) {
        setloading(true);
        projectIndex = activeEditor.root_key[0];
        sectionIndex = activeEditor.root_key[1];
        const subSectionIndex = activeEditor.root_key[2];
        taskIndex = activeEditor.root_key[3];
        const prev =
          result.projects[projectIndex].sections[sectionIndex].sections[
            subSectionIndex
          ].tasks[taskIndex - 1];
        result.projects[projectIndex].sections[sectionIndex].sections[
          subSectionIndex
        ].tasks[taskIndex - 1] = { ...activeEditor.data, order: prev.order };
        result.projects[projectIndex].sections[sectionIndex].sections[
          subSectionIndex
        ].tasks[taskIndex] = {
          ...prev,
          order: activeEditor.data.order,
        };
        TemplateLib.editTask(activeEditor.data.id, {
          ...activeEditor.data,
          order: prev.order,
          actions: [],
        })
          .then(() => {
            TemplateLib.editTask(prev.id, {
              ...prev,
              order: activeEditor.data.order,
              actions: [],
            })
              .then(() => {
                updateTree({
                  ...result,
                  selected: [
                    projectIndex,
                    sectionIndex,
                    subSectionIndex,
                    taskIndex - 1,
                  ].join("-"),
                });
                updateActiveEditor({
                  ...activeEditor,
                  data: { ...activeEditor.data, order: prev.order },
                  root_key: [
                    projectIndex,
                    sectionIndex,
                    subSectionIndex,
                    taskIndex - 1,
                  ],
                });
                setloading(false);
              })
              .catch((response) =>
                config.handleErrorBackend({ response, message: "" })
              );
          })
          .catch((response) =>
            config.handleErrorBackend({ response, message: "" })
          );
      }
    }
  };

  const OrderDown = () => {
    if (activeEditor.root_key.length === 3) {
      projectIndex = activeEditor.root_key[0];
      sectionIndex = activeEditor.root_key[1];
      taskIndex = activeEditor.root_key[2];
      if (
        taskIndex + 1 !==
        result.projects[projectIndex].sections[sectionIndex].tasks.length
      ) {
        setloading(true);
        const next =
          result.projects[projectIndex].sections[sectionIndex].tasks[
            taskIndex + 1
          ];
        result.projects[projectIndex].sections[sectionIndex].tasks[
          taskIndex + 1
        ] = { ...activeEditor.data, order: next.order };
        result.projects[projectIndex].sections[sectionIndex].tasks[taskIndex] =
          {
            ...next,
            order: activeEditor.data.order,
          };
        TemplateLib.editTask(activeEditor.data.id, {
          ...activeEditor.data,
          order: next.order,
          actions: [],
        })
          .then(() => {
            TemplateLib.editTask(next.id, {
              ...next,
              order: activeEditor.data.order,
              actions: [],
            })
              .then(() => {
                updateTree({
                  ...result,
                  selected: [projectIndex, sectionIndex, taskIndex + 1].join(
                    "-"
                  ),
                });
                updateActiveEditor({
                  ...activeEditor,
                  root_key: [projectIndex, sectionIndex, taskIndex + 1],
                  data: { ...activeEditor.data, order: next.order },
                });
                setloading(false);
              })
              .catch((response) =>
                config.handleErrorBackend({ response, message: "" })
              );
          })
          .catch((response) =>
            config.handleErrorBackend({ response, message: "" })
          );
      }
    }

    if (activeEditor.root_key.length === 4) {
      projectIndex = activeEditor.root_key[0];
      sectionIndex = activeEditor.root_key[1];
      const subSectionIndex = activeEditor.root_key[2];
      taskIndex = activeEditor.root_key[3];
      setloading(true);
      const next =
        result.projects[projectIndex].sections[sectionIndex].sections[
          subSectionIndex
        ].tasks[taskIndex + 1];
      result.projects[projectIndex].sections[sectionIndex].sections[
        subSectionIndex
      ].tasks[taskIndex + 1] = { ...activeEditor.data, order: next.order };
      result.projects[projectIndex].sections[sectionIndex].sections[
        subSectionIndex
      ].tasks[taskIndex] = {
        ...next,
        order: activeEditor.data.order,
      };
      TemplateLib.editTask(activeEditor.data.id, {
        ...activeEditor.data,
        order: next.order,
        actions: [],
      })
        .then(() => {
          TemplateLib.editTask(next.id, {
            ...next,
            order: activeEditor.data.order,
            actions: [],
          })
            .then(() => {
              updateTree({
                ...result,
                selected: [
                  projectIndex,
                  sectionIndex,
                  subSectionIndex,
                  taskIndex + 1,
                ].join("-"),
              });
              updateActiveEditor({
                ...activeEditor,
                data: { ...activeEditor.data, order: next.order },
                root_key: [
                  projectIndex,
                  sectionIndex,
                  subSectionIndex,
                  taskIndex + 1,
                ],
              });
              setloading(false);
            })
            .catch((response) =>
              config.handleErrorBackend({ response, message: "" })
            );
        })
        .catch((response) =>
          config.handleErrorBackend({ response, message: "" })
        );
    }
  };

  const getTypeTaskLabel = (type) => {
    switch (type) {
      case "text":
        return "Texte informatif";
      case "payment":
        return "Paiement en ligne";
      case "interview":
        return "Génération de documents";
      case "electronic":
        return "Signature de documents";
      case "form":
        return "Formulaire";
      case "upload":
        return "Upload de fichiers";
      case "courrier":
        return "Courrier";
      case "rdv":
        return "Prise de rdv visio";
      default:
        return "Introuvable";
    }
  };

  const getPrice = (arr) => {
    var total = 0;
    for (var i in arr) {
      total += +arr[i].amount;
    }
    return total;
  };

  const ifParentTask = () => {
    // if (_formData.actions[0].type === "interview") {
    //     return true;
    // } else return false;
    return false;
  };

  const onParentCategorieChange = (id) => {
    TemplateLib.getListTaskOfCategories(id).then((res) => {
      set_formData({
        ..._formData,
        category_id: id,
        order: res.at(-1) ? res.at(-1)?.order + 1 : 0,
      });
    });
  };

  const isDownButtonDisabled = () => {
    let isDisabled = true;
    if (
      activeEditor.root_key.length === 3 &&
      Array.isArray(result.projects) &&
      Array.isArray(result.projects[projectIndex]?.sections)
    ) {
      isDisabled =
        taskIndex + 1 ===
        result.projects[projectIndex]?.sections[sectionIndex]?.tasks.length;
    }
    if (
      activeEditor.root_key.length === 4 &&
      Array.isArray(result.projects) &&
      Array.isArray(result.projects[projectIndex]?.sections) &&
      Array.isArray(
        result.projects[projectIndex]?.sections[activeEditor.root_key[1]]
          .sections
      )
    ) {
      isDisabled =
        result.projects[projectIndex]?.sections[activeEditor.root_key[1]]
          ?.sections[activeEditor.root_key[2]]?.tasks.length ===
        activeEditor.root_key[3] + 1;
    }
    return isDisabled;
  };

  return (
    <Grid container spacing={2}>
      {loading ? (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CircularProgress size={20} />{" "}
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginBottom: "5px",
          }}
        >
          <div style={{ marginRight: "10px" }}>Ordre</div>
          <Button
            size="small"
            variant="contained"
            disabled={taskIndex - 1 === -1}
            onClick={OrderUp}
          >
            <ArrowUpwardIcon />
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={isDownButtonDisabled()}
            onClick={OrderDown}
          >
            <ArrowDownwardIcon />
          </Button>

          <FormControl
            size="small"
            fullWidth
            variant="outlined"
            style={{ marginLeft: "10px" }}
          >
            <InputLabel>Section mère</InputLabel>
            <Select
              label="Section mère"
              value={_formData.category_id}
              onChange={(e) => {
                onParentCategorieChange(e.target.value);
              }}
            >
              {categories.map((el, key) => (
                <MenuItem key={el.id} value={el.id}>
                  {el.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}

      <NameField
        _formData={_formData}
        set_formData={set_formData}
        label="Nom de la tâche"
      />
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <div
          style={{ fontSize: "18px", display: "flex", alignItems: "center" }}
        >
          <b style={{ marginRight: "10px" }}>Type de la tâche :</b>{" "}
          {_formData.actions.length > 0 ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              {getIconTask(_formData.actions[0].type)}{" "}
              {getTypeTaskLabel(_formData.actions[0].type)}
            </div>
          ) : (
            <small className="description"> Introuvable</small>
          )}
        </div>
      </Grid>
      <MandatoryField
        _formData={_formData}
        set_formData={set_formData}
        label={"Tâche facultative "}
      />
      {_formData.mandatory && (
        <Dependencies
          _formData={_formData}
          set_formData={set_formData}
          activeEditor={activeEditor}
        ></Dependencies>
      )}
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {_formData.actions.length > 0 ? (
          <WysiwygTaskEditor _formData={_formData} set_formData={set_formData}/>
        ) : (
          <div
            style={{
              border: "1px solid silver",
              height: "300px",
              width: "100%",
              backgroundColor: "white",
            }}
          ></div>
        )}
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Divider
          style={{
            margin: "10px 10% 20px 10%",
            width: "80%",
            borderRadius: "5px",
            height: "1px",
            backgroundColor: `rgba(${
              localStorage.getItem("color-task")
                ? localStorage.getItem("color-task")
                : config.defaultColors.task
            },1)`,
          }}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        {_formData.actions.length &&
          typeof _formData.actions[0].data !== "string" &&
          getTaskType(
            _formData.actions[0].type,
            _formData,
            set_formData,
            _paymentForm,
            set_paymentForm,
            editTask
          )}
      </Grid>
      {/* <TaskStatuses _formData={_formData} set_formData={set_formData} /> */}
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Delete _function={deleteTask} loading={_loading} />
        <Modify _function={editTask} loading={_loading} />
      </Grid>
    </Grid>
  );
}
