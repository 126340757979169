import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function WysiwygTaskEditor(props) {
  const { _formData, set_formData } = props;

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "link"], // --
      ["blockquote", "code-block"],

      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // --
      [{ indent: "-1" }, { indent: "+1" }], // --

      [{ size: ["small", false, "large", "huge"] }], // --

      [{ color: [] }, { background: [] }], // --
      [{ font: [] }],
      [{ align: [] }],

      ["clean"],
    ],
  };

  return (
    <div className="text-editor" style={{ backgroundColor: "white" }}>
      <ReactQuill
        theme="snow"
        placeholder="Description de la tâche"
        modules={modules}
        value={
          _formData.actions[0].data.text ? _formData.actions[0].data.text : ""
        }
        onChange={(e) =>
          set_formData({
            ..._formData,
            actions: [
              {
                ..._formData.actions[0],
                data: { ..._formData.actions[0].data, text: e },
              },
            ],
          })
        }
      />
    </div>
  );
}
